const Summary = () => {
  return (
    <div className='flex flex-col justify-start items-start h-auto w-full mb-12'>
      <h2 className='h-auto w-full text-xl font-bold'>SUMMARY</h2>
      <p className='text-lg text-gray-700'>
        I have experience in developing RESTful systems and writing test code,
        following the principles of SOLID and various design patterns. I
        understand the importance of integrating testing into the development
        process. I have a good command of spoken English and can freely read
        technical documentation. I am committed to continuous professional
        growth in software development and hold a degree in Information
        Technology.
      </p>
    </div>
  )
}

export default Summary
