import ExternalLink from '../../components/ExternalLink'

const Experience = () => {
  return (
    <div className='flex flex-col justify-start items-start h-auto w-full mb-12'>
      <h2 className='h-auto w-full text-xl font-bold'>
        WORK EXPERIENCE AND ACHIEVEMENTS
      </h2>

      <h6 className='h-auto w-full text-md font-bold flex flex-row justify-between items-center mb-0.5 text-gray-800'>
        <span>
          Development of a Fullstack Document Management Application (diploma
          work)
        </span>
        <span className='font-normal text-gray-700'>07/2024</span>
      </h6>

      <p className='text-lg text-gray-700'>
        Development of a service for electronic document management. The project
        is at the development stage.
      </p>

      <p className='text-lg mb-4'>
        <ExternalLink
          name={'Source code'}
          url='https://github.com/TimSalakh/DocStorage'
        />
      </p>

      <h6 className='h-auto w-full text-md font-bold flex flex-row justify-between items-center mb-0.5 text-gray-800'>
        <span>Development of a Fullstack email application (coursework)</span>
        <span className='font-normal text-gray-700'>04-05/2024</span>
      </h6>

      <p className='text-lg text-gray-700'>
        <span className='underline'>Backend</span> - Developed a REST API using
        ASP.NET Core, created a PostgreSQL database from scratch using EF Core,
        optimized queries. Implemented user authentication and authorization
        functionality using JWT tokens. Three-layer architecture.
      </p>

      <p className='text-lg text-gray-700'>
        <span className='underline'>Frontend</span> - Developed a client
        application that interacts with the REST API on the backend. Created a
        modern and intuitive interface using React, TypeScript and Tailwind CSS.
      </p>

      <p className='text-lg'>
        <ExternalLink
          name={'Source code'}
          url='https://github.com/TimSalakh/XChange'
        />
      </p>
    </div>
  )
}

export default Experience
