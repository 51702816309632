import ExternalLink from '../../components/ExternalLink'

const Experience = () => {
  return (
    <div className='flex flex-col justify-start items-start h-auto w-full mb-12'>
      <h2 className='h-auto w-full text-xl font-bold'>
        ОПЫТ РАБОТЫ И ДОСТИЖЕНИЯ
      </h2>

      <h6 className='h-auto w-full text-md font-bold flex flex-row justify-between items-center mb-0.5 text-gray-800'>
        <span>
          Разработка Fullstack приложения по документообороту (дипломная работа)
        </span>
        <span className='font-normal text-gray-700'>07/2024</span>
      </h6>

      <p className='text-lg text-gray-700'>
        Разработка сервиса по электронному документооборту. Проект на стадии
        разработки.
      </p>

      <p className='text-lg mb-4'>
        <ExternalLink
          name={'Исходный код'}
          url='https://github.com/TimSalakh/DocStorage'
        />
      </p>

      <h6 className='h-auto w-full text-md font-bold flex flex-row justify-between items-center mb-0.5 text-gray-800'>
        <span>
          Разработка Fullstack приложения электронной почты (курсовая работа)
        </span>
        <span className='font-normal text-gray-700'>04-05/2024</span>
      </h6>

      <p className='text-lg text-gray-700'>
        <span className='underline'>Backend</span> - Спроектировал и разработал
        REST API на ASP.NET Core с использованием трехслойной архитектуры.
        Настроил базу данных PostgreSQL через EF Core, применяя миграции для
        управления изменениями схемы. Оптимизировал запросы для повышения
        производительности. Реализовал аутентификацию и авторизацию
        пользователей с использованием JWT токенов.
      </p>

      <p className='text-lg text-gray-700'>
        <span className='underline'>Frontend</span> - Разработал клиентское
        приложение на React TypeScript, взаимодействующее с REST API на бэкэнде.
        Создал современный и интуитивно понятный интерфейс, используя Tailwind
        CSS.
      </p>

      <p className='text-lg'>
        <ExternalLink
          name={'Исходный код'}
          url='https://github.com/TimSalakh/XChange'
        />
      </p>
    </div>
  )
}

export default Experience
