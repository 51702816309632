const Summary = () => {
  return (
    <div className='flex flex-col justify-start items-start h-auto w-full mb-12'>
      <h2 className='h-auto w-full text-xl font-bold'>ОБЩЕЕ</h2>
      <p className='text-lg text-gray-700'>
        Имею опыт разработки RESTful систем и написания тестируемого кода,
        следуя принципам SOLID и различным паттернам проектирования. Разбираюсь
        в принципах разработки с учетом тестирования. Хорошо владею разговорным
        английским и свободно читаю техническую документацию. Стремлюсь к
        постоянному развитию в области разработки. Имею профильное образование в
        области информационных технологий.
      </p>
    </div>
  )
}

export default Summary
